<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>

    <div class="content-container">
      <h1 class="text-bold">深圳家居设计周</h1>
      <div>
        
        <div class="section">
          <p class="title text-bold">一、展会简介</p>
          <p class="p1">深圳家居设计周暨深圳国际家具展，创立于1996年，由深圳市家具行业协会、居然之家、富森美家居联合主办，深圳创意产业联盟协办，深圳市德赛展览有限公司承办，深圳市工业和信息化局作为指导单位，深圳家具研究开发院作为产业技术支持单位、赛德检测作为产品品质监控单位，是中国家居也高品质商业设计展。</p>
          <p class="p1">深圳家居设计周坚持“设计导向、潮流引领、持续创新”，以设计为纽带，与城市文化共融的深圳国际家具展，日益成为家具和设计界认识深圳的窗口，也成为“国际设计资源与中国制造连接”及“中国制造寻找国际、国内市场”的战略平台，以高标准驱动中国家居产业高质量发展。</p>
          <p class="p1">2023年5月25-28日，同期将举办九大主题展览，全国top200大牌集结的中国家具原创新品首发展“深圳国际家具设计展”、国际设计联动中国设计桥梁的“Design Dome select设计万象特别策展”、创造全场景智能生活新生态的“SMART智家展”、代表当代年轻消费场域与艺术文化创新圈层的“皆可百货·新消费生活展”、聚焦创新材料与国潮IP的“设计灵感·家居材料展”和“空间艺术设计展”、探索设计光域智能光解决的“ON LIGHT光·生活展”、引领公建产业潮流的“国际公建设计艺术展”、展现新时代户外生活新业态的“国际户外生活展”。九大展览，将呈现从户外生活到美好人居，健康公建的全城市全场景智慧城市人居解决方案。</p>
          <p class="p1">这一展会生态，在全球范围内都是独一无二，具有差异化优势和核心竞争力。届时，不仅有来自全国 40 多万专业买家来深观展，也会吸引全国乃至全球会展业和大家居产业上下游资源汇聚深圳，为深圳家具传统优势产业的转型和发展提供强大动能。</p>
          <p class="p2" style="margin-top: 10px;">时间：3月16-19日（2025年定档3月16-19日）</p>
          <p class="p2">频次：一年一届</p>
          <p class="p2">地址：深圳国际会展中心（广东省深圳市宝安区福海街道展城路1号）</p>
          <p class="p2">规模：32万平米</p>
        </div>
        <div class="section">
          <p class="title text-bold">二、展会历程</p>
          <p class="p2">1996年至今，已举办39届展会。</p>
          <p class="p2">2025年，深圳家居设计周暨40届深圳国际家具展。</p>
          <img src="https://exhoss39.szcreativeweek.com/applet/img/history.png" alt="" class="history">
        </div>
        <div class="section">
          <p class="title text-bold">三、展会业态</p>
          <p class="p1">深圳家居设计周不止于家具，而是横跨住宅、家具、灯饰、智能、建材、材料、艺术、生活、公建与户外全人居场景的大家居一体化商业设计展。</p>
          <img src="https://exhoss39.szcreativeweek.com/applet/img/business.png" alt="" class="history">
          
          <p class="p3 text-bold">1）深圳国际家具设计展</p>
          <p class="p2">中国家具原创新品首发</p>
          <p class="p2">看家具大牌，看家具新品，就来深圳国际家具设计展。众多活动家具转型“成品+定制”，全案整装新势力将是本届家具展一大看点，还有现代两厅、睡眠空间、原创设计、智能家具、儿童家具等优势板块的诸多新品……全国家具Top200年度大秀正在集结。</p>
          
          <p class="p3 text-bold">2）Design Dome设计万象</p>
          <p class="p2">国际·艺术·设计</p>
          <p class="p2">2022年，深圳家居设计周平行展 Design Dome设计万象帮助了诸多参展商进入并拓展了中国尤其是深圳乃至大湾区市场。</p>
          <p class="p2">2023年5月25日至28日，Design Dome将以设计万象特别策展Design Dome Select形式回归， 我们通过特别策划，展示国际设计精选品牌、中国原创设计板块、设计师策展，以及家具和家居装饰的最新创新展示。</p>
          
          <p class="p3 text-bold">3）SMART+智家展</p>
          <p class="p2">全场景智能生活新生态</p>
          <p class="p2">智能家居是趋势，全案智能定制更是产业所向。集结全场景智能技术平台方、智能家居终端提供方、智慧电器破局者、智慧康养引领者的SMART智家展，将展现全场景智能生活新生态。</p>
          
          <p class="p3 text-bold">4）皆可百货·新消费生活展</p>
          <p class="p2">当代年轻消费场域与艺术文创新圈层</p>
          <p class="p2">消费复苏正当时。生活方式的美学感知与消费流行的产物抉择在不断变化，深层次决定未来的商业走向。在皆可百货·新消费生活展，艺术潮玩、创意饰品、东方文化的新玩法、新圈层，眼见为实。</p>
          
          <p class="p3 text-bold">5）设计灵感·家居材料展</p>
          <p class="p2">炼物志·studio HBA特别策展</p>
          <p class="p2">任何创新都需要灵感，任何创作都离不开材料。设计灵感·家居材料展，超级IP“炼物志”再升级，将文创推向科技，studio HBA特别策展聚焦令设计师心动的材料，等等，在这里每个人都能找到想要的灵感和解决方案。</p>
          
          <p class="p3 text-bold">6）空间艺术设计展·大宅设计+&花园集</p>
          <p class="p2">未来，是设计主导的时代。空间、建材、软装、景观等的一体化创作已经被设计师关注，最考创作的大宅设计，最贴近生活调性的花园集，全新的空间艺术设计展，是设计师们创作的艺术庭院。</p>
          
          <p class="p3 text-bold">7）ON LIGHT光·生活展</p>
          <p class="p2">设计光·智能光·光方案</p>
          <p class="p2">ON LIGHT光展是深圳家居设计周主题展览之一，不仅专注设计提升，同时致力商业市场对接，旨在将灯饰品牌与灯饰设计师链接融合。通过内容的先锋性与实践性，让观众游走于艺术、设计与商业之间，在不同的领域探讨灯光艺术的历史、现状与前景。</p>
          
          <p class="p3 text-bold">8）国际公建设计艺术展</p>
          <p class="p2">政府、商业办公解决方案、公建设计艺术前沿趋势，是这个领域的主要方向。国际公建设计艺术展，有顶尖的设计机构、国内外优秀供应链品牌，是公建产业发展的潮流指南。</p>
          
          <p class="p3 text-bold">9）国际户外生活展</p>
          <p class="p2">户外露营·户外运动·高尔夫·健身运动</p>
          <p class="p2">"露营风"，"户外+"，当代年轻人的生活和消费主场正在向户外延伸。5月户外生活展，汇聚户外运动，高尔夫运动，健身运动三大主题展览，独家策划极限飞盘、极限城市、尾箱集市、桨板挑战、水池派对、路亚嘉年华等落地活动，多元运动空间场景，沉浸式互动体验，展现新时代户外生活新业态。</p>
          
          <p class="p3">欢迎加入深圳家居设计周，携手共赢未来。</p>
        </div>
        
        <div class="section">
          <div class="more text-bold text-center">
            <span>往届盛况与活动,</span>
            <span>关注深圳家居设计周官方公众号了解更多：</span>
          </div>
          <div class="flex">
            <div class="flex-sub text-center">
              <img src="https://exhoss39.szcreativeweek.com/applet/img/gongzhonghao.png" alt="" class="qrcode">
              <span class="code-name text-center">官方公众号二维码</span>
            </div>
            <div class="flex-sub text-center">
              <img src="https://exhoss39.szcreativeweek.com/applet/img/guangzhanapplet.png" alt="" class="qrcode">
              <span class="code-name text-center">逛展小程序二维码</span>
            </div>
          </div>
        </div>
      
      </div>

    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'intro',
    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style scoped lang="less">

@media screen and (min-width: 1024px) {
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 190px;
    margin: 20px auto ;
    
    h1 {
      font-size: 8px;
      text-align: center;
    }
    
    .section {
      margin: 10px 0;
    }
    .title {
      font-size: 4px;
      margin: 0 0 4px;
    }
    
    .p1 {
      text-indent: 2em;
      font-size: 3.6px;
      line-height: 1.8em;
      text-align: justify;
      margin-top: 1.5px;
    }
    .p2 {
      text-indent: 2em;
      font-size: 3.6px;
      line-height: 1.8em;
      text-align: justify;      
    }
    .p3 {
      text-indent: 2em;
      font-size: 3.6px;
      line-height: 1.8em;
      text-align: justify;
      margin: 6px 0 1.5px;
    }
    .p4 {
      font-size: 3.6px;
      line-height: 1.8em;
      text-align: justify;
      margin: 0 0 4px;
    }
    
    .history {
      display: block;
      margin: 4px auto;
      width: 100%;
      height: auto;
    }
    
    .more {
      font-size: 3.6px;
      padding: 8px 0 4px;
    }
    
    .qrcode {
      display: block;
      width: 40px;
      height: auto;
      margin: 2px auto;
    }
    
    .theme-intro-box {
      font-size: 3.6px;
      line-height: 2em;
      
      li {
        list-style-type: disc;
        list-style-position: outside;
        margin-left: 8px;
      }
    }

  }
}

// 移动端
@media screen and (max-width: 1023px) {
  .content-container {
    width: 100%;
    padding: 20px;
    
    h1 {
      font-size: 18px;
      text-align: center;
      margin-bottom: 30px;
    }
    
    .section {
      margin: 10px 0;
    }
    .title {
      font-size: 14px;
      margin: 0 0 14px;
    }
    
    .p1 {
      text-indent: 2em;
      font-size: 14px;
      text-align: justify;
      margin-top: 8px;
    }
    .p2 {
      text-indent: 2em;
      font-size: 14px;
      line-height: 1.8em;
      text-align: justify;      
    }
    .p3 {
      text-indent: 2em;
      font-size: 14px;
      text-align: justify;
      margin: 20px 0 10px;
    }
    
    .history {
      display: block;
      margin: 10px auto;
      width: 100%;
      height: auto;
    }
    
    .more {
      font-size: 14px;
      line-height: 2em;
      padding: 20px 0 10px;
      
      span {
        display: block;
      }
    }
    
    .qrcode {
      display: block;
      width: 80px;
      height: auto;
      margin: 2px auto;
    }
    
    .code-name {
      font-size: 12px;
      line-height: 3em;
    }
  }
}
</style>